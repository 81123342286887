.FooterParticipant {
    width: 100vw;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background: #DEE2E6;
    justify-content: space-between;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 40;
}

.FooterPreview {
    width: 90%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background: #DEE2E6;
    justify-content: space-between;
    display: flex;
    position:fixed;
    bottom: 0;
    left: 8rem;
    right:5rem;
    z-index: 40;
}