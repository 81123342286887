.custom-card {
    margin-top: 1%;
    width: 100%;
    height: auto; /* Ini akan membuat tinggi menyesuaikan isi teks */
    padding: 2%;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 2px; /* Rounded border */
  }

.title-text{
    /* font-family: 'Segoe UI'; */
    font-weight: 600;
    margin-bottom: 1%;
  }
  
.description-text {
    text-align: justify;
    font-family: 'Segoe UI';
   font-size: 16px;
    white-space: pre-wrap; /* Memastikan teks menjaga format baris baru */
    word-wrap: break-word; /* Memastikan teks tidak melampaui lebar kartu */
    margin: 0; /* Menghapus margin bawaan untuk elemen <pre> */
  }



