/* src/styles.css */
.tree-node {
    margin-left: 1vw;
    border-left: 0.1rem solid #000000;
    padding-left: 0.4rem;
    position: relative;
    display: flex;
  }
  
  .node-toggle {
    /* cursor: pointer; */
    transition: color 0.3s;
  }
  

  .child-nodes {
    list-style: none;
    padding-left: 0rem;
    margin-bottom: 0 !important;
  }
  
  .child-nodes > li {
    margin: 0.5rem 0;
  }
