* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App .openModalBtn {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}
body {
  padding: 0;
  margin: 0;
}

.btn-danger{
  border-color: #DC3545 ;
  background-color: #DC3545 ;
  color: white ;
}

.btn-danger:hover{
  border-color: #C82333 ;
  background-color: #C82333 ;
  color: white ;
}

.btn-info{
  border-color: #17A2B8 ;
  background-color: #17A2B8 ;
  color: white ;
}

.btn-info:hover{
  border-color: #138496 ;
  background-color: #138496 ;
  color: white ;
}

.btn-warning{
  border-color: #FFC107 ;
  background-color: #FFC107 ;
  color: white ;
}

.btn-warning:hover{
  border-color: #E0A800 ;
  background-color: #E0A800 ;
  color: white ;
}

.btn-success{
  border-color: #28A745 ;
  background-color: #28A745 ;
  color: white ;
}

.btn-success:hover{
  border-color: #218838 ;
  background-color: #218838 ;
  color: white ;
}

.btn-primary{
  border-color: #7749F8 ;
  background-color: #7749F8 ;
  color: white ;
}

.btn-primary:hover{
  border-color: #5227CC ;
  background-color: #5227CC ;
  color: white ;
}

.form-control{
  border-color: rgb(209 213 219) !important;
}


.table {
  margin: 0 !important;
}

.swal-title{
  font-size: 1.5rem;
}

.container {
  overflow-y: unset !important;
}

.swal-footer {
  margin-top: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.btn-new-style {
  padding: 0 24px !important;
  line-height: 36px !important;
  font-weight: 600 !important;
}

.swal-text{
  text-align: center;
}

.swal-button--confirm{
  border-color: #7749F8 ;
  background-color: #7749F8 ;
  color: white ;
}

.swal-button--confirm:hover{
  border-color: #5227CC ;
  background-color: #5227CC ;
  color: white ;
}