.introduction-title{
    color: #5227CC;
    /* font-family: Poppins; */
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

.select-survey:hover{
  cursor: pointer;
}

.disabled {
  background-color: #ccc; /* Warna abu-abu */
  color: #666; /* Warna teks abu-abu */
  cursor: not-allowed; /* Mengubah kursor menjadi not-allowed saat dinonaktifkan */
}