.tooltips {
    position: relative;
    display: inline-block;
    /* display: flex; */

  }
  
  .tooltips .tooltiptext {
    visibility: hidden;
    background-color: #6C757D;
    color: white;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    font-size: smaller;
  }
  
  .tooltips:hover .tooltiptext {
    visibility: visible;
  }