.container{
  padding-bottom: 50px !important;
}

.custom-card {
  margin-top: 5vh;
  width: 100%;
  height: auto; /* Ini akan membuat tinggi menyesuaikan isi teks */
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 2px; /* Rounded border */
}

.judul-text{
  /* font-family: 'Segoe UI'; */
  font-weight: 600;
}

.isi-text {
  text-align: justify;
  /* font-family: 'Segoe UI'; */
  word-wrap: break-word;
}

.question-card {
  margin-top: 2.5vh;
  width: 100%;
  height: auto; /* Ini akan membuat tinggi menyesuaikan isi teks */
  padding-right: 1px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px black solid;
  display: flex;
  
}

.question-card-vertical {
  margin-top: 2.5vh;
  width: 100%;
  height: auto; /* Ini akan membuat tinggi menyesuaikan isi teks */
  padding-right: 1px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px black solid;
  display: flex;
  padding: 20px;
}

.question-card-green {
  margin-top: 2.5vh;
  width: 100%;
  height: auto; /* Ini akan membuat tinggi menyesuaikan isi teks */
  padding-right: 1px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px #2BA745;
  display: flex;
  gap: 20px;
}

.isi-text-question {
  text-align: justify;
  /* font-family: 'Segoe UI';; */
  padding: 20px;
  /* word-wrap: break-word; */
  border-right: 1px black dotted;
  width: 70%;
}



.isi-text-question-vertical {
  text-align: justify;
  /* font-family: 'Segoe UI';; */
  padding: 20px;
  word-wrap: break-word;
}

.custom-text-area {
  margin-top: 3vh;
  height: 100%;
  width: 100%;
}

.pilihan-question {
  text-align: justify;
  /* font-family: 'Segoe UI';; */
  padding: 20px;
  width: 60%;
}

/* CSS untuk mengatur tampilan radio button dengan label di bawahnya */
.custom-radio-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.custom-radio-label label {
  margin-top: 5px; /* Atur jarak antara radio button dan label */
}

.custom-radio-label-khusus {
  margin-top: 40px;
}

.dragbar {
  width: 100%;
}

.pilihan-warna {
  background-color: #ADB5BD;
}

.question-card-mandatory {
  margin-top: 2.5vh;
  width: 100%;
  height: auto; /* Ini akan membuat tinggi menyesuaikan isi teks */
  padding-right: 1px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px black solid;
  /* display: flex; */
  
}