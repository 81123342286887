.btn-info{
    background-color: #17A2B8 !important;
}

.btn-info:hover:enabled{
    background-color: #138496 !important;
}

.icon-btn{
    justify-content: center;
    width: 40px ;
    height: 40px ;
    border-radius: 100% ;
}

.icon-btn-sm{
    justify-content: center;
    width: 30px ;
    height: 30px ;
    border-radius: 100% ;
}

.color-danger:enabled{
    color: #C82333;
}

.color-danger:disabled{
   color: rgba(200, 35, 51, 0.2);
}

.color-danger:hover:enabled{
    background-color: rgba(200, 35, 51, 0.2);
}

.color-warning:enabled{
    color: #FFC107;
}

.color-warning:hover:enabled{
    color: #E0A800;
    background-color: rgba(200, 35, 51, 0.2);
}

.color-warning:disabled{
    color: rgba(200, 35, 51, 0.2);
}

/* .color-view{
/* .color-view{
    border-color: #17A2B8;
    background-color: #17A2B8;
    
}

.color-view:hover{
    border-color: #138496;
    background-color: rgba(19, 132, 150, 0.2);
} */

.color-view:enabled{
    color: #17A2B8;
}

.color-view:hover:enabled{
    background-color: rgba(200, 35, 51, 0.2);
}

.color-view:disabled{
    color: rgba(200, 35, 51, 0.2);
}



.color-success:enabled{
    color: #28A745;
}

.color-success:hover:enabled{
    background-color: rgba(200, 35, 51, 0.2);
}

.color-success:disabled{
    color: rgba(200, 35, 51, 0.2);
}


.color-primary:enabled{
    color: #7749F8;
}

.color-primary:hover:enabled{
    background-color: rgba(200, 35, 51, 0.2);
}

.color-primary:disabled{
    color: rgba(200, 35, 51, 0.2);
}

.outline-color-warning{
    border-color: #FFC107;
    background-color: #FFFFFF;
    color: #FFC107;
}

.outline-color-warning:hover{
    border-color: #FFFFFF;
    background-color: #FFC107;
    color: #FFFFFF;
}


.icon-btn-dropdown{
    border-radius: 100% !important;
    justify-content: center !important;
    align-self: center !important;
    width: 40px !important;
    height: 40px !important;
}


.color-primary-dropdown:enabled{
    color: #7749F8 ;
   
}
.color-primary-dropdown{
    background-color: transparent !important;
    border-color:transparent !important;
}

.icon-btn-dropdown::after{
   display: none !important;
}

.icon-btn-dropdown:hover{
    background-color: rgba(200, 35, 51, 0.2) !important;
    /* border-color: rgba(200, 35, 51, 0.2) !important; */
    color: #7749F8 !important;
}

.icon-btn-dropdown:checked{
    background-color: rgba(200, 35, 51, 0.2) !important;
    /* border-color: rgba(200, 35, 51, 0.2) !important; */
    color: #7749F8 !important;
}

.icon-btn-dropdown.show{
    background-color: rgba(200, 35, 51, 0.2) !important;
    /* border-color: rgba(200, 35, 51, 0.2) !important; */
    color: #7749F8 !important;
}

.icon-btn-dropdown:active{
    background-color: rgba(200, 35, 51, 0.2) !important;
    color: #7749F8 !important;
}


.color-primary-dropdown:disabled{
    color: rgba(200, 35, 51, 0.2) !important;
}


.color-general:hover:enabled{
    background-color: rgba(200, 35, 51, 0.2);
}

.color-general:disabled{
    color: rgba(200, 35, 51, 0.2);
}
