body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  .header {
    padding: 20px;
    background: #333;
    color: white;
    text-align: center;
  }
  
  .header button {
    background: #666;
    border: none;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }
  
  .summary-cards {
    display: flex;
    justify-content: space-around;
    margin: 20px;
  }
  
  .summary-cards .card {
    background: white;
    padding: 20px;
    flex: 1;
    margin: 0 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    text-align: center;
  }
  
  .survey-tables {
    margin: 20px;
  }
  
  .survey-table {
    margin-bottom: 20px;
  }
  
  .survey-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .survey-table table th, .survey-table table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .survey-table table th {
    background: #f4f4f4;
  }

  .chart-survey-year{
    width: 100%;
    height:'400px';
  }